import * as ReactDom from 'react-dom';
import * as React from 'react';
import { BrowserRouter } from "react-router-dom";
import { App } from "./app/App";

ReactDom.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
    document.querySelector('app'));
