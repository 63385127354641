import React from 'react'

function PackagesLoading() {
    return (
        <>
            <div className="card w-25">
                <div className="card-1 animate-pulse"> </div>
                <div className="card-2 p-3">
                    <div className="row">
                        <div className="col-4">
                            <div className="inner-card animate-pulse"> </div>
                        </div>
                        <div className="col-8">
                            <div className="inner-card animate-pulse"> </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-6">
                            <div className="inner-card animate-pulse"> </div>
                        </div>
                        <div className="col-6">
                            <div className="inner-card animate-pulse"> </div>
                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}

export default PackagesLoading
