import * as React from "react";
import { ChangeEvent, Dispatch, FormEvent, SetStateAction } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import { FormInterface } from "./interfaces/Form";
import { AppContext } from "./App";
import Image from "url:../assets/img/img.png";
import { useHistory } from "react-router-dom";

firebase.initializeApp({
  apiKey: "AIzaSyDJTtxPUf2dK7UvcBvgxP8JapyFK6pxw7s",
  authDomain: "connectportal-5c29e.firebaseapp.com",
  projectId: "connectportal-5c29e",
  storageBucket: "connectportal-5c29e.appspot.com",
  messagingSenderId: "907652278292",
  appId: "1:907652278292:web:8cebec0b33617f0d581f01",
});

export const Login = () => {
  const context = React.useContext(AppContext);

  let [getPhone, setPhone] = React.useState("");
  const handlePhoneChange = (event: ChangeEvent) =>
    setPhone(event.target.value);
  let [getPhoneDisabled, setPhoneDisabled] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [form, setForm]: [FormInterface, (f: FormInterface) => any] = React.useState();
  const history = useHistory();

  const submitPhone = async (event: FormEvent) => {
    event.preventDefault();
    setPhoneDisabled(true);
    try {
      const phoneNumber = "+218" + getPhone.replace(/^0|\s/, "");
      window.confimarionResult = await firebase
        .auth()
        .signInWithPhoneNumber(phoneNumber, window.recaptchaVerifier);
      sessionStorage.setItem("phone", phoneNumber);
      history.push("/otpLogin");
    } catch (e) {
      setPhoneDisabled(false);
      throw e;
    }
  };

  React.useEffect(() => {
    if (!firebase.apps.length) {
      firebase.initializeApp({});
    } else {
      firebase.app(); // if already initialized, use that one
    }
  }, []);

  // @ts-ignore
  React.useEffect(async () => {
    const formResponse = await fetch(
      "https://services-api.connect.ly/online-signup.php"
    );

    const formResource = (await formResponse.json()) as { data: FormInterface };
    setForm(formResource.data);
    setLoading(false);
  }, []);

  React.useEffect(() => {
    // @ts-ignore
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
      }
    );
  }, []);
  return (
    <>
      <div className="row">
        <div
          className="col-md-8"
          dangerouslySetInnerHTML={{
            __html: form?.description?.[context.language],
          }}
        />
        <div className="col-md-4 d-none d-md-block">
          <img
            src={Image}
            style={{ position: "sticky", top: "2em" }}
            className="img-fluid"
          />
        </div>
        <div className="col-lg-4 col-md-8">
          <div className="card shadow border-bottom-0 rounded-0 rounded-bottom border-start-0 border-end-0 border-3 border-connect">
            <div className="card-body">
              <form onSubmit={submitPhone} className="form-group my-2">
                <label>ادخل رقم هاتفك</label>
                <div className="input-group">
                  <input
                    lang="en"
                    className="form-control"
                    type="tel"
                    name="phone"
                    pattern="09[0-9]{8}"
                    onChange={handlePhoneChange}
                    placeholder="09xxxxxxxx"
                    disabled={getPhoneDisabled}
                    required
                  />
                </div>
                <button
                  className={`btn btn-connect btn-sm my-2 ${
                    getPhoneDisabled ? "d-none" : ""
                  }`}
                  id="sign-in-button"
                >
                  استمرار
                </button>
              </form>
              
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
