import React from 'react'

function FormStatus(form) {
    switch (form.submits?.[0]?.status) {
        case "processing":
          return (
            <>
              <div className="alert my-3 p-2 alert-info">قيد المراجعة</div>
            </>
          );
        case "approved":
          return (
            <>
              <div className="alert my-3 p-2 alert-success">
                تم اعتماد البيانات
              </div>
            </>
          );
  
        case "declined":
          return (
            <>
              <div className="alert my-3 p-2 alert-danger">
                لم يتم اعتماد البيانات, يرجى المراجعة
              </div>
            </>
          );
        default:
          return <></>;
      }
}

export default FormStatus
