import React from 'react'
import { Product } from '../../interfaces/Product'

interface params {
    product: Product,
    setSelectedProduct: (arg) => void
}

const PackagesCard = (params: params) => {

    const onSelectPackage = () => {

    }

    return (
        <>
            <div className="col-sm-6 col-md-4 col-lg-3 my-3">
                <label
                    className="card shadow h-100 rounded-bottom rounded-0 border-0">
                    <img src={params.product.image} className="card-img-top" />
                    <div className="card-body d-flex">
                        <div>
                            <input type="radio" required name="product"
                                onChange={(e) => params.setSelectedProduct(params.product)} />
                        </div>
                        <div className="flex-grow-1 ms-1">
                            <small>{params.product.name}</small>
                            <div className="text-connect font-weight-bold">{params.product.price} د.ل.</div>
                        </div>
                    </div>
                </label>
            </div>
        </>
    )
}

export default PackagesCard
