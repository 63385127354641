import React from "react";
import useReturnToken from "./hooks/useReturnToken";
import './Invoice.scss'

export default function Invoices() {
  const [invoices, setInvoices] = React.useState([]);
  const token = useReturnToken();

  React.useEffect(() => {
    getInvcoies();
  }, []);

  const getInvcoies = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token} `);

    var requestOptions: RequestInit = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const invoiceResponse = await fetch(
        "https://api-online-subscription.connect.ly/api/online-subscription/invoice",
        requestOptions
      ).then((res) => res);

      const invoiceResource = await invoiceResponse.json();
      console.log("invoicesData", invoiceResource);

      setInvoices(invoiceResource.data);
    } catch (e) {
      console.log("e products", e);
    }
  };
  return (
    <div>
      <div>
        <h2 className="text-connect font-weight-bold "> سجل </h2>
      </div>
      <h5 className="text-muted font-weight-light mt-1">
        {invoices?.length ? invoices.length : ""} اشتراك
      </h5>


      {invoices?.length && (
        <div >
          <table className="table mt-4 table-responsive">
            <thead>
              <tr>
                <th scope="col">
                  <strong> # </strong>
                </th>
                <th scope="col">
                  <strong> تاريخ الاشتراك </strong>
                </th>
                <th scope="col">
                  <strong> حالة الاشتراك </strong>
                </th>
                <th scope="col">
                  <strong> تم الاسترجاع </strong>
                </th>
                <th scope="col">
                  <strong> ملاحظات </strong>{" "}
                </th>
              </tr>
            </thead>
            <tbody>
              {invoices?.map((invoice, index) => (
                <tr key={index}>
                  <th scope="row">{invoice.invoice.id}</th>

                  <td>{invoice.invoice.created_at}</td>
                  <td> {invoice.invoice.status} </td>
                  <td> {invoice.invoice.is_refund ? "نعم" : "لا"} </td>
                  <td> {invoice.invoice.note != null ? "" : invoice.note} </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {!invoices?.length && <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-12 text-center display-1">
            <i className="far fa-3x fa-list-alt text-muted"></i>
            <h4 className='text-muted font-weight-bold mt-2'> لا يوجد اشتراكات </h4>
          </div>
        </div>
      </div>}
    </div>
  );
}
