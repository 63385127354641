import * as React from 'react';
import './ImageInput.scss';

    

import {ChangeEvent, DragEvent} from "react";

export const ImageInput = ({value, onChange, disabled, id, className = '', required, ...args}) => {

    const [imageSrc, setImageSrc] = React.useState(value);
    const [draggedOver, setDraggedOver] = React.useState(false);
    
    // console.log('imagesrc', imageSrc)
    
    


    const fileChange = async ($event: DragEvent<HTMLInputElement> | ChangeEvent<HTMLInputElement>) => {


         
        const target = ($event.target) as HTMLInputElement;
        const files = (target.files || ($event as DragEvent).dataTransfer.files) as FileList;
        if (files.length) {
                const reader = new FileReader();
               
                reader.onload = () => {
                   
                    setImageSrc(reader.result);

                 
           
                    onChange({
                            target: {
                                value: reader.result
                            }
                        })
                 
                };
           
                reader.readAsDataURL(files[0])
            
          
        } else {
            // this.hasImage = false;
            // this.value = {};
        }
    };

    return <div
        className={`form-image-input ${imageSrc ? 'has-image': ''} ${draggedOver ? 'dragging-over' : ''} ${!disabled ? 'interactive' : 'disabled'} ${className}`}>
        <input  type="hidden"  value={imageSrc} {...args}/>
        <div className={`image`} style={{backgroundImage: `url(${imageSrc})`}}>
            <input  type="file" required={required} id={id} accept="image/png, image/jpeg"
                   onChange={fileChange}
                   onDragEnter={() => setDraggedOver(true)}
                   onDragOver={() => setDraggedOver(true)}
                   onDragEnd={() => setDraggedOver(false)}
                   onDragLeave={() => setDraggedOver(false)}
                   onDrop={fileChange}/>
            <label htmlFor={id}>
                <img className="upload-icon"
                     src="data:image/svg+xml, %3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='file-upload' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 384 512' class='svg-inline--fa fa-file-upload fa-w-12 fa-3x'%3E%3Cpath fill='%23909090' d='M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm65.18 216.01H224v80c0 8.84-7.16 16-16 16h-32c-8.84 0-16-7.16-16-16v-80H94.82c-14.28 0-21.41-17.29-11.27-27.36l96.42-95.7c6.65-6.61 17.39-6.61 24.04 0l96.42 95.7c10.15 10.07 3.03 27.36-11.25 27.36zM377 105L279.1 7c-4.5-4.5-10.6-7-17-7H256v128h128v-6.1c0-6.3-2.5-12.4-7-16.9z' class=''%3E%3C/path%3E%3C/svg%3E%0A"
                     alt="upload"/>
            </label>
        </div>
    </div>
}
