import React from "react";
import { useHistory } from "react-router-dom";
import "../style.scss";

export default function Dashboard() {
  const history = useHistory();

  const navigateToInvoices = () => {
    history.push("/invoices");
  }

  const navigateToForm = () => {
    history.push("/signupform");
  }


  return (
    <div>
      <div className="container" style={{ maxHeight: "500vh" }}>
          <div
            className="row mt-4 justify-content-center align-items-center dash-row"
          >
            <div className="col-lg-4 col-sm-12 sub-tab text-center">
              <button
                onClick={() => {
                  navigateToForm();
                }}
                className="btn btn-lg  dash-btn phone w-100 "
              >
                <i className="fas fa-user-plus icon fa-5x text-connect mb-3 mt-2"></i>

                <p>اشتراك جديد</p>
              </button>
            </div>
            <div
              className="col-lg-4 AppHistory  col-sm-12 text-center "
            >
              <button
                onClick={() => {
                  navigateToInvoices()
                }}
                className="btn dash-btn btn-lg phone w-100"
              >
                <i className="fas fa-5x fa-file-invoice icon text-connect mb-3 mt-2"></i>
                <p>سجل الاشتراكات</p>
              </button>
            </div>
          </div>
      </div>
    </div>
  );
}
