import * as React from 'react';
import {Entry} from "./interfaces/Form";
import {ImageInput} from "./ImageInput";



const entryTypes = {
    checkbox: 'boolean',
};

const parseValue = (value, type: 'string' | 'number' | 'boolean') => {
    switch (type) {
        case "boolean":
            return typeof value === 'string' ? value === 'true' : value;
        case "number":
            return +value;
        default:
            return value;
    }
}

export const FormEntry = (
    {
        entry,
        index,
        disabled,
        valueChange,
        validationMessage,
    }: {
        entry: Entry,
        index: number,
        disabled: boolean;
        valid?: boolean,
        validationMessage: String,
        valueChange: (entry_id: number, value: any) => void
    }) => {
        const autosize = React.useRef((element: HTMLTextAreaElement) => {
           if (element) element.style.height = 'auto';
           if (element) element.style.height = element?.scrollHeight + 'px';
           if (element) element.style.overflowY = 'hidden';
           if (element) element.style.resize = 'none';
           if (element) element.addEventListener('input', event => {
                element.style.height = 'auto';
                element.style.height = element?.scrollHeight + 'px';
                element.style.overflowY = 'hidden';
                element.style.resize = 'none';
            })
        })     

    const [value, setValue] = React.useState(
        () => {
       return parseValue(entry.answers?.[0]?.value, entryTypes[entry.type])
    })

    const defaultProps = {
    
        required: entry.is_required === 1 ? true : false,
        name: `answers[${index}][value]`,
        id: entry.id.toString(),
        disabled,
        value : value || '',
        onChange: (v) => {
            if (v.target.type === 'checkbox') {
                setValue(v.target.checked);
            } else {
                setValue(v.target.value);
            }
        },
    }

    // these entries have been cancelled
    if([2,4,5].includes(entry.id)) return <></>

    const formEntries = (type: string) => {
        if (type === 'date')
            return <input className="form-control" type="date"  required={defaultProps.required} {...defaultProps}/>
        else if (type === 'tel')
            return <input className="form-control" type="tel" required={defaultProps.required}  {...defaultProps}/>
        else if (type === 'textarea')
            return <textarea ref={autosize.current} required={defaultProps.required}  rows={3} className="form-control" {...defaultProps}/>
        else if (type === 'checkbox')
            return <input type="checkbox" required={defaultProps.required}  {...defaultProps} checked={value}/>
        else if (type === 'image')
            return <ImageInput  {...defaultProps}/>
        else
            return <input required={defaultProps.required}  className="form-control" type="text" {...defaultProps}/>
    }

    return <>
        {
           formEntries(entry.type)
        }
        {entry.is_required && !value ? <p style={{color:'red'}}> {validationMessage}  </p> : ""}
    </>
}
