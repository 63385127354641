import React, { ChangeEvent, FormEvent } from 'react'
import { useHistory } from "react-router-dom";
import { createState, useState } from '@hookstate/core';
import { Persistence } from '@hookstate/persistence';
import { store } from '../store';

declare global {
  interface Window {
    confimarionResult: any;
  }
}

const OtpLogin = () => {
    
  const stateObject = useState(store);
  let [getCodeDisabled, setCodeDisabled] = React.useState(false);
  let [getCode, setCode] = React.useState("");
  const history = useHistory();
  
  React.useEffect(() => console.log("IM IN OTP"))

  const submitCode = async (event: FormEvent) => {
    event.preventDefault();
    setCodeDisabled(true);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      phone_number: "0945718535",
    });

    var requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    try {
      const response = await window?.confimarionResult?.confirm(getCode);
      const firebaseToken = await response?.user?.getIdToken();
      myHeaders.append("Authorization", `Bearer ${firebaseToken} `);

      const loginResponse = await fetch(
        "https://api-online-subscription.connect.ly/api/online-subscription/login",
        requestOptions
      ).then((response) => response);

      const body = await loginResponse.json();
      localStorage.setItem("token", body.body.access_token);
      sessionStorage.isSubscribed  = body.body.subscripted;
      stateObject.set(body.body.access_token);
      stateObject.attach(Persistence('state'));
      history.push("/");
      // this.firebaseTokenInput.value = this.firebaseToken;
    } catch (e) {
      setCodeDisabled(false);
      throw e;
    }
  };

  
  const handleCodeChange = (event: any) => setCode(event.target?.value);

    return (
        
            <form onSubmit={submitCode} className="form-group my-2">
              <div className="input-group">
                <input
                  lang="en"
                  className="form-control"
                  type="tel"
                  name="phone"
                  pattern="[0-9]{6}"
                  onChange={handleCodeChange}
                  disabled={getCodeDisabled}
                  required
                />
              </div>
              <small>أدخل الرمز الذي تم ارساله الى رقم هاتفك</small>
              <br />
              <button
                className={`btn btn-connect btn-sm my-2  ${
                  getCodeDisabled ? "d-none" : ""
                }`}
              >
                تأكيد الرمز
              </button>
            </form>
    )
}

export default OtpLogin
