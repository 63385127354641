import { useState } from "@hookstate/core";
import { store } from "../../store";

const useReturnToken = () => {
  const stateObject = useState(store);
  const localTokenObj: any = localStorage.getItem("token");

  const token = stateObject.get() ? stateObject.get() : localTokenObj;

  return token;
}

export default useReturnToken;