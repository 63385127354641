import { useState } from "@hookstate/core";
import { formIdStore } from "../../store";

const useFormId = () => {
    const stateObject = useState(formIdStore);
    const localFormId: any = JSON.parse(localStorage.getItem("formId"));
    console.log('stateObject', stateObject)
    const form_id = stateObject.get() ? stateObject.get() : localFormId;
    return form_id;
}

export default useFormId;