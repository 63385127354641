
    export const paymentMethodInfos = {
        1: {
            label: 'رقم هاتف الخاص بالادفع لي',
            placeholder: '09xxxxxxxx',
            pattern: '09[0-9]{8}',
            confirmationLabel: 'الرجاء ادخال الرمز الذي تم ارساله الى هاتفك',
            confirmationPattern: '[0-9]{4}',
        },
        3: {
            label: 'رقم الخاص بالموبي كاش',
            confirmationLabel: 'الرجاء ادخال الرمز الذي تم ارساله الى هاتفك',
        }
        // 3: {
        //     label: 'رقم الاشاري',
        //     extraContent() {
        //         if (!product?.id) {
        //             return <></>;
        //         }
        //         const data = {
        //             "user": "010365",
        //             "serviceName": "شركة العنكبوت الليبي لتقنية المعلومات",
        //             "amount": products.find(p => p.id === product?.id)?.price,
        //         };
        //         return <>
        //             <div className="m-2 text-center d-flex flex-column">
        //                 <img style={{maxHeight: '200px', margin: 'auto'}}
        //                      src={`https://api.qrserver.com/v1/create-qr-code/?charset-source=UTF-8&data=${encodeURIComponent(JSON.stringify(data))}`}/>
        //                 <small><b>الرجاء مسح الQR <br/> باستخدام التطبيق</b></small>
        //             </div>
        //         </>
        //     }
        // },
    };