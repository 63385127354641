import React, { FormEvent } from "react";
import { paymentMethodInfos } from "./utils/paymentMethods";
import { PaymentMethod } from "./interfaces/PaymentMethod";
import { City } from "./interfaces/City";
import { FormInterface } from "./interfaces/Form";
import { FormDataJson } from "form-data-json-convert";
import $ from "jquery";
import Toastify from "toastify-js";
import useReturnToken from "./hooks/useReturnToken";
import useSelectedProduct from "./hooks/useSelectedProduct";
import useFormId from "./hooks/useFormId";
import { useHistory } from "react-router-dom";

export default function Checkout({
  form
}: {
  form: FormInterface;
}) {
  window.Lightbox = window.Lightbox || {};
  const [confirmationReady, setConfirmationReady] = React.useState(false);
  const [disabledConfirmation, setDisabledConfirmation] = React.useState(false);
  const [payment, setPayment] = React.useState(false);
  const [cityId, setCityId] = React.useState(null);
  const [coupon, setCoupon] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const [orderId, setOrderId] = React.useState(null);
  const [disabled, setDisabled] = React.useState(false);
  const [cities, setCities] = React.useState([] as City[]);
  const token = useReturnToken();
  const product = useSelectedProduct();
  const formId = useFormId();
  const history = useHistory();
  const [pos, setPOS] = React.useState([]);
  const [posId, setPOSId] = React.useState<String>(pos[0]?.id);
  const [paymentMethodId, setPaymentMethodId] = React.useState(null);

  React.useEffect(() => {
   
    if(product.id !== 4)
      setPaymentMethods([{
        id: 1,
        name: "ادفع لي",
        image: "test",
      },
      {
        id: 2,
        name: "سداد",
        image: "test",
      },
      {
        id: 3,
        name: "موبي كاش",
        image: "test",
      },
      {
        id: 4,
        name: "نقدي",
        image: "test",
      },
      {
        id: 5,
        name: "معاملات",
        image: "test",
      }
     ])
    else
      setPaymentMethods([
        {
          id: 5,
          name: "معاملات",
          image: "test",
        }
    ])
  }, [])
  
  const [paymentMethods, setPaymentMethods] = React.useState<PaymentMethod[]>([]);

  const phone = sessionStorage.phone.replace("+218", "0");

  React.useEffect(() => {
    getCities();
    getPOS();
    setPaymentMethodId(null);
    setCoupon(null);
  }, []);

  const handleCouponChange = (e) => {
    if (e.target.value.length === 12) {
      submitCoupon(e.target.value);
    } else setCoupon(null);
  };

  const submitCoupon = async (couponCode) => {
    setDisabled(true);
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);
    myHeaders.append("Content-Type", "application/json");
    const data = {
      productID: product,
      coupon: couponCode,
    };

    var raw = JSON.stringify(data);

    var requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const couponResponse = await fetch(
        "https://api-online-subscription.connect.ly/api/online-subscription/coupon-check",
        requestOptions
      ).then((response) => response);

      const couponResource = await couponResponse.json();
      setCoupon(couponResource);

      setDisabled(false);
    } catch (e) {
      console.log("e", e);
    }
  };

  const getPOS = async () => {
    setLoading(true);

    var requestOptions: RequestInit = {
      method: "GET",
      headers: { "Authorization": `Bearer ${token}` },
      redirect: "follow",
    };

    try {
      const posResponse = await fetch(
        `https://api-online-subscription.connect.ly/api/online-subscription/pos`,
        requestOptions
      ).then((res) => res);
      const posResult = await posResponse.json();
      setPOS(posResult.data);
      setLoading(false);
    } catch (e) {
      throw (e);
    }
  };

  const submitConfirmation = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDisabledConfirmation(true);
    const formElement = event.target as HTMLFormElement;
    // const formData = new FormData(formElement);
    const data = FormDataJson.formToJson(formElement);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    myHeaders.append("Authorization", `Bearer ${token} `);

    var raw = JSON.stringify(data);

    var requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        `https://api-online-subscription.connect.ly/api/online-subscription/confirm`,
        requestOptions
      )
        .then((res) => res.json())
        .then((result) => result);
      if (!response.status) {
        Toastify({
          text: response.message,
          duration: 3000,
          backgroundColor: "#e01c0a",
        }).showToast();
      } else {
        Toastify({
          text: response.message,
          duration: 3000,
          backgroundColor: "#32a852",
        }).showToast();
      }

      if (!response.status) {
        throw response;
      }

      setPayment(true);
    } catch (e) {
      if (e.data) {
        Toastify({
          text: e.data,
          duration: 3000,
          backgroundColor: "#e01c0a",
        }).showToast();
      }
    }
    reset();
  };

  const reset = () => {
    setConfirmationReady(false);
    setDisabled(false);
    setDisabledConfirmation(false);
  };

  const getCities = async () => {
    setLoading(true);

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token} `);

    var requestOptions: RequestInit = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    try {
      const citiesResponse = await fetch(
        `https://api-online-subscription.connect.ly/api/online-subscription/city`,
        requestOptions
      ).then((res) => res);
      const citiesResult = await citiesResponse.json();

      setCities(citiesResult.data);
      setLoading(false);
    } catch (e) {
      console.log("e payments", e);
    }
  };

  const submitCheckout = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDisabled(true);
    const formElement = event.target as HTMLFormElement;
    // const formData = new FormData(formElement);
    const data = FormDataJson.formToJson(formElement);
    console.log("data", data)
    data["productID"] = product.id;

    console.log('product id :',product.id);
    
    data["formId"] = formId;
    // data["posID"] = posId ? posId : pos[0].id;
    if (!data.coupon) data.coupon = null;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token} `);

    var raw = JSON.stringify(data);

    var requestOptions: RequestInit = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    try {
      const response = await fetch(
        "https://api-online-subscription.connect.ly/api/online-subscription/checkout",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => result);
      setOrderId(response.order_id);

      if (!response.status) {
        Toastify({
          text: "يوجد خطأ في خدمة الدفع",
          duration: 3000,
          backgroundColor: "#e01c0a",
        }).showToast();
        throw response;
      }
      if (+paymentMethodId === 5) {

        console.log("response.moamalat", response.moamalat)
        window.Lightbox.Checkout.configure = {
          MID: response.moamalat.mid,
          TID: response.moamalat.tid,
          AmountTrxn: response.moamalat.amount,
          MerchantReference: response.moamalat.merchRef,
          TrxDateTime: response.moamalat.time,
          SecureHash: response.moamalat.hmac,
          completeCallback: function (data) {
            Toastify({
              text: "تم الدفع ✅",
              duration: 3000,
              backgroundColor: "#e01c0a",
            }).showToast();
          },
          errorCallback: function (data) {
            console.log('error');
            console.log(data);
          },
          cancelCallback: function () {
            history.push('/');
          }
        };

        window.Lightbox.Checkout.showLightbox();
      }

      if (+paymentMethodId === 2 && response.serialize) {
        const url = `https://store.connect.ly/checkout/pgw/sadad/pay?src=${encodeURIComponent(
          "signup.connect.ly"
        )}&s_data=${response.serialize}&order_id=${response.order_id
          }&u_token=signup`;
        window.location.assign(url.toString());
        return;
      }
      if (paymentMethodId == 4) {
        setPayment(true)
      } else
        setConfirmationReady(true);
    } catch (e) {
      if (e.data) {
        Toastify({
          text: e.data,
          duration: 3000,
          backgroundColor: "#e01c0a",
        }).showToast();
      }
      setDisabled(false);
    }
  };

  if (payment) {
    return (
      <>
        <h3>الاشتراك</h3>
        <h4 className="text-connect font-weight-bold">
          تم الاشتراك, يرجى الانتظار حتى يتم التواصل معك
        </h4>
      </>
    );
  }

  if (loading) {
    return (
      <>
        <span className="spinner-grow m-auto text-connect d-block" />
      </>
    );
  } else
    return (
      <div>

        <h3> الدفع </h3>
        <form className="row" onSubmit={submitCheckout}>
          <input type="hidden" name="phoneNumber" value={phone} />
          <div className="col-12" />
          {/* <div className="col-md-4 my-3"> */}
          {/* Modal */}
          <h5 className="text-muted font-weight-light mt-1">
            اجمالي القيمة :{" "}
            {coupon && coupon?.status ? (
              <>
                <del className="text-danger"> {product?.price} </del>{" "}
                <strong> {coupon?.price_after_discount} </strong>
              </>
            ) : (
              product?.price
              
            )}
          </h5>

          <div className="col-md-4 my-3">
            <div className="form-group">
              <label htmlFor="city">المدينة</label>
              <select
                disabled={disabled}
                name="cityID"
                required
                id="city"
                className="form-control"
                onChange={(event) => {
                  setCityId(event.target.value);
                }}
              >
                {cities?.map((c) => (
                  <option selected={c.id === cityId} value={c.id}>
                    {c.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-md-4 my-3">
            <div className="form-group">
              <label htmlFor="payment_method">طريقة الدفع</label>
              <select
                disabled={disabled}
                name="paymentID"
                required
                id="payment_method"
                className="form-control"
                onChange={(event) => {
                  setPaymentMethodId(event.target.value);
                }}
              >
                <option selected value="" disabled />
                {paymentMethods.map((payment_method) => (
                  <option
                    selected={payment_method.id === paymentMethodId}
                    value={payment_method.id}
                  >
                    {payment_method.name}
                  </option>
                ))}
              </select>
            </div>
          </div>

          {paymentMethodId == 4 ?

            <div className="col-md-4 my-3">
              <div className="form-group">
                <label htmlFor="city"> نقطة البيع </label>
                <select
                  disabled={disabled}
                  name="cityID"
                  required
                  id="city"
                  className="form-control"
                  onChange={(event) => {
                    setPOSId(event.target.value);
                  }}
                >
                  {pos.map((pos) => (
                    <option selected={pos.id === cityId} value={pos.id}>
                      {pos.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            : ''}
          {paymentMethodInfos[paymentMethodId] || paymentMethodId == 2 || paymentMethodId === 4 ? (
            <>
              <div className="col-md-4 my-3">
                <div className="form-group">
                  <label htmlFor="paymentCode">هل لديك كوبون تخفيض؟</label>
                  <input
                    maxLength={12}
                    disabled={disabled}
                    type="text"
                    name="coupon"
                    placeholder="ادخل قسيمة الخصم"
                    onChange={(e) => handleCouponChange(e)}
                    className="form-control"
                  />
                  {coupon?.status ? (
                    <i className="fas fa-check text-success">
                      تم الخصم بنجاح
                    </i>
                  ) : coupon?.errors ? (
                    <i className="fas text-danger fa-exclamation-circle">
                      غير صالح للاستخدام
                    </i>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <>

                {paymentMethodId == 2 || paymentMethodId == 4 ? (
                  ""
                ) : (
                  <div className="col-md-4 my-3">
                    <div className="form-group">
                      <label htmlFor="paymentCode">
                        {paymentMethodInfos[paymentMethodId]?.label ||
                          "payment code"}
                      </label>
                      <input
                        disabled={disabled}
                        type="text"
                        required
                        name="paymentCode"
                        placeholder={
                          paymentMethodInfos[paymentMethodId]?.placeholder
                        }
                        pattern={paymentMethodInfos[paymentMethodId]?.pattern}
                        className="form-control"
                      />
                    </div>
                  </div>
                )}
              </>
              {paymentMethodId == 2 || paymentMethodId == 4
                ? ""
                : paymentMethodInfos[paymentMethodId].extraContent?.(form)}
            </>
          ) : (
            <>
              <input type="hidden" name="paymentCode" value="0" />
            </>
          )}
          <div className="col-12" />
          <div className="col-12" />
          <div className={`col-auto ${confirmationReady ? "d-none" : ""}`}>
            <div className="form-group">
              <button disabled={disabled} className="btn btn-primary">

                {disabled ? (
                  <div className="spinner-border text-light" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : "الاستمرار"}
              </button>
            </div>
          </div>
        </form>
        {confirmationReady ? (
          <>
            <form className="row" onSubmit={submitConfirmation}>
              <input type="hidden" name="orderID" value={orderId} />
              <div className="col-md-6 my-3">
                <div className="form-group">
                  <label htmlFor="paymentCode">
                    {paymentMethodInfos[paymentMethodId]?.confirmationLabel ||
                      "تأكيد"}
                  </label>
                  <div className="input-group">
                    <input
                      disabled={disabledConfirmation}
                      type="text"
                      required
                      name="pin"
                      placeholder={
                        paymentMethodInfos[paymentMethodId]
                          ?.confirmationPlaceHolder
                      }
                      pattern={
                        paymentMethodInfos[paymentMethodId]?.confirmationPattern
                      }
                      className="form-control"
                    />
                    <button
                      disabled={disabledConfirmation}
                      className="btn btn-primary"
                    >
                      {disabledConfirmation ? (
                        <div className="spinner-border text-light" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      ) : "الاستمرار"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </>
        ) : (
          <></>
        )}
      </div>
    );
}
