import { useState } from '@hookstate/core';
import { Persistence } from '@hookstate/persistence';
import * as React from 'react';
import { useHistory } from "react-router-dom";
import { productStore } from '../store';
import PackagesLoading from './Components/Loading/PackagesLoading';
import PackagesCard from './Components/Packages/PackagesCard';
import useReturnToken from './hooks/useReturnToken';
import { Product } from "./interfaces/Product";

const urlParams = new URL(window.location.toString()).searchParams;

const sadad = async () => {
    if (urlParams.has('s_data') && urlParams.has('order_id')) {

        try {
            const response = await fetch('https://services-api.connect.ly/online-subscription/online-subscription-confirmation.php', {
                method: 'POST',
                body: JSON.stringify({
                    orderID: urlParams.get('order_id'),
                    token: urlParams.get('s_data'),
                }),
            });
            console.log('response', response)
        } catch (e) { console.log(e) }
        return;
    }
}

sadad();

export const Packages = function () {

    const [loading, setLoading] = React.useState(true);
    const [products, setProducts] = React.useState([] as Product[]);
    const [selectedProduct, setSelectedProduct] = React.useState({} as Product);
    const stateObject = useState(productStore);
    const token = useReturnToken();
    const history = useHistory();

    React.useEffect(() => {
        getProducts();
    }, []);

    React.useEffect(() => {
        localStorage.setItem("product", JSON.stringify(selectedProduct));
        stateObject.set(selectedProduct);
        stateObject.attach(Persistence('product'));
    }, [selectedProduct])

    const navigateToFormSubmit = () => history.push("/formSubmit")

    const getProducts = async () => {
        setLoading(true);
        var myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${token} `);
        var requestOptions: RequestInit = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };
        try {
            const productResponse = await fetch('https://api-online-subscription.connect.ly/api/online-subscription/product', requestOptions).then(res => res);
            const productResource = (await productResponse.json()) as { data: Product[] };
            setProducts(productResource.data);
            setLoading(false);
        }
        catch (e) {
            console.warn('e products', e)
        }
    }

    return <>
        <form className="row" >
            <div className="mb-3">
                <h4 className="text-connect font-weight-bold"> اختر باقة الاشتراك</h4>
            </div>
            {loading ? <PackagesLoading /> : products?.map((product: Product) => <PackagesCard key={product.id} setSelectedProduct={setSelectedProduct} product={product} />)}
        </form>

        <div className="d-flex justify-content-end">
            <button type="button" className="btn btn-primary text-white" onClick={navigateToFormSubmit}>Select Package</button>
        </div>

    </>
};
