import React from "react";
import Checkout from "./Checkout";
import { FormSubmit } from "./FormSubmit";
import { Product } from "./interfaces/Product";
import { Packages } from "./Packages";
import { AppContext } from "./App";
import Address from "./Address";
import { FormInterface } from "./interfaces/Form";
import { useHistory } from "react-router-dom";

export default function Form({ token }) {
  const [loading, setLoading] = React.useState(true);
  const [form, setForm]: [FormInterface, (f: FormInterface) => any] = React.useState();
  const context = React.useContext(AppContext);
  const history = useHistory();

  const navigateToPackages = () => {
    history.push("/packages");
  }

  //@ts-ignore
  React.useEffect(async () => {
    const formResponse = await fetch("https://services-api.connect.ly/online-signup.php");
    const formResource = (await formResponse.json()) as { data: FormInterface };
    setForm(formResource.data);
    setLoading(false);
  }, []);

  return (
    <div>
      <div
        dangerouslySetInnerHTML={{
          __html: form?.description?.[context.language],
        }}
      />
      <div className="col-12 col-lg-2 mb-2 mt-4">
        <button
          style={{ color: "white" }}
          onClick={() => navigateToPackages()}
          className="btn btn-lg btn-connect w-100"
        >
          ابدأ في الاشتراك
        </button>
      </div>
    </div>
  );
}
