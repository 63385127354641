import { useState } from "@hookstate/core";
import { productStore } from "../../store";

const useSelectedProduct = () => {
    const stateObject = useState(productStore);
    const localProduct: any = JSON.parse(localStorage.getItem("product"));
    const product = stateObject.id.get() ? stateObject.get() : localProduct;
    return product;
}

export default useSelectedProduct;