import * as React from "react";
import { BrowserRouter, Redirect, Switch } from "react-router-dom";
import history from "../history";
import Checkout from "./Checkout";
import ProtectedRoute from "./Components/RouteComponents/ProtectedRoute";
import SessionRoute from "./Components/RouteComponents/SessionRoute";
import Dashboard from "./Dashboard";
import Form from "./Form";
import { FormSubmit } from "./FormSubmit";
import useReturnToken from "./hooks/useReturnToken";
import { FormInterface } from "./interfaces/Form";
import Invoices from "./Invoices";
import { Login } from "./Login";
import OtpLogin from "./OtpLogin";
import { Packages } from "./Packages";

declare global {
  interface Window {
      base_url: string;
  }
}


window.base_url = "service.connect.ly/";

let context = {
  language: "ar",
  fetch: (input: RequestInfo, init?: RequestInit) => {
    if (typeof input === "string") {
      input = window.base_url + input;
    } else {
      input = { ...input, url: window.base_url + input.url };
    }
    return fetch(input, init);
  },
};

export const AppContext = React.createContext(context);

export const App = () => {
  const [loading, setLoading] = React.useState(true);
  const [form, setForm]: [FormInterface, (f: FormInterface) => any] = React.useState();
  const token = useReturnToken();

  //@ts-ignore
  React.useEffect(async () => {
    const formResponse = await fetch("https://services-api.connect.ly/online-signup.php");
    const formResource = (await formResponse.json()) as { data: FormInterface };
    setForm(formResource.data);
    setLoading(false);
  }, []);

  // if (loading) {
  //   return (
  //     <>
  //       <div className="container-fluid" style={{ height: "100vh" }}>
  //         <div className="d-flex flex-column h-100">
  //           <span className="spinner-grow m-auto text-connect" />
  //         </div>
  //       </div>
  //     </>
  //   );
  // }

  return (
    <>
      <AppContext.Provider value={context}>
        <div className="container-fluid py-5" style={{ minHeight: '85vh' }}>
          <div className="container-md" >
            <h1 className="mx-auto">{form?.name[context.language]}</h1>
            <div className="p-3 p-md-5 border border-end-0 border-top-0 border-bottom-0 border-4 border-connect">

              <BrowserRouter history={history}>
                <Switch>

                  <SessionRoute
                    exact
                    path="/login"
                    token={token}
                    component={Login} />

                  <SessionRoute
                    exact
                    path="/otpLogin"
                    token={token}
                    component={OtpLogin} />

                  <ProtectedRoute
                    exact
                    path="/dashboard"
                    token={token}
                    component={Dashboard} />

                  <ProtectedRoute
                    exact
                    path="/invoices"
                    token={token}
                    component={Invoices} />

                  <ProtectedRoute
                    exact
                    path="/signupform"
                    token={token}
                    component={Form} />

                  <ProtectedRoute
                    exact
                    path="/packages"
                    token={token}
                    component={Packages} />

                  <ProtectedRoute
                    exact
                    path="/checkout"
                    token={token}
                    component={Checkout} />

                  <ProtectedRoute
                    exact
                    path="/formSubmit"
                    token={token}
                    component={FormSubmit} />

                  <Redirect from="/" to="/dashboard" />

                  {/* {token ? 
             (
            
         <Dashboard setNewSub={setNewSub} history={Apphistory} newSub={newSub} setHistory={setAppHistory} setDashboard={setDashboard} form={form} token={stateObject} />
               ) 
               :  (
                <>
                  <Login form={form} setToken={setToken} />
                </>
              )
              } */}
                </Switch>
              </BrowserRouter>
            </div>
          </div>
        </div>
        <div className="container-fluid bg-dark py-5">
          <div className="container">
            <div className="row justify-content-start">
              <div className="col-12 col-lg-2 text-center">
                <a href="https://connect.ly" className="btn btn-outline-light w-100 m-1">
                  العودة الى Connect.ly
                </a>

              </div>
              {!token ? <div className="col-12 col-lg-2 text-center">
                <button
                  onClick={() => {
                  }}
                  className={`btn btn-outline-light m-1 w-100 ${token ? "" : "d-none"}`}
                >
                  الصفحة الرئيسية
                </button>
              </div>
                : ""}

              <div className="col-12 col-lg-2 text-center">
                <button
                  onClick={() => {
                    localStorage.removeItem("token");
                    sessionStorage.removeItem("phone");
                    localStorage.removeItem("state");
                    window.location.reload();
                  }}
                  className={`btn btn-outline-light m-1 w-100 ${token ? "" : "d-none"}`}
                >
                  تسجيل الخروج
                </button>
              </div>
            </div>
          </div>
        </div>
      </AppContext.Provider>
    </>
  );
};
