import $ from "jquery";
import * as React from "react";
import Toastify from "toastify-js";
import { AppContext } from "./App";
import Checkout from "./Checkout";
import FormStatus from "./Components/Form/FormStatus";
import { FormEntry } from "./FormEntry";
import useReturnToken from "./hooks/useReturnToken";
import { useState } from '@hookstate/core';
import { FormInterface } from "./interfaces/Form";
import { dataURItoBlob } from "./utils/dataURItoBlob";
import { useHistory } from "react-router-dom";
import useSelectedProduct from "./hooks/useSelectedProduct";
import { formIdStore } from "../store";
import { Persistence } from "@hookstate/persistence";

export const FormSubmit = () => {
  const formNames = [
    "name",
    "nid",
    "phone_number",
    "optional_phone_number",
    "address",
    "document",
  ];
  const [message, setMessage] = React.useState("");
  const [form, setForm]: [FormInterface, (f: FormInterface) => any] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const context = React.useContext(AppContext);
  const formRef = React.useRef<any>();
  const token = useReturnToken();
  const history = useHistory();
  const stateObject = useState(formIdStore);
  const product = useSelectedProduct();
  const status = !form?.submits?.length ? "pending" : form.submits?.[0]?.status;
  const [disabled, setDisabled] = React.useState(
    !["pending", "declined"].includes(status)
  );
  const [data, setData] = React.useState(
    form?.entries?.reduce((obj, entry) => {
      obj[entry.id] = entry.answers?.[0]?.value;
      return obj;
    }, {})
  );

  console.log("product", product.price);


  React.useEffect((): any => fetchForm(), []);

  const fetchForm = async () => {
    const formResponse = await fetch("https://services-api.connect.ly/online-signup.php");
    const formResource = (await formResponse.json()) as { data: FormInterface };
    setForm(formResource.data);
    setLoading(false);
  }


  const [validationMessage, setValidationMessage] = React.useState<string>("");

  const sizes = {
    sm: "col-md-4",
    md: "col-md-6",
    lg: "col-12",
  };

  const inputChange = (entry_id, value) => {
    data[entry_id] = value;
    setData(data);
  };

  React.useEffect(() => {
    if (formRef.current) {
      formRef.current.addEventListener('invalid', (e) => {
        console.log('e invalid', e.target)
        if (e.target.validationMessage) {
          setValidationMessage(".يرجى ارفاق مستند")
        }
      }, { capture: true })
      formRef.current.addEventListener("submit", () => onSubmit(event));
    }
  }, []);

  const onSubmit = async (event) => {

    event.preventDefault();

    const formData = new FormData();

    var dataSerialized = $("form")
      .serializeArray()
      .reduce((obj, item) => {
        obj[item.name] = item.value;
        return obj;
      }, {});

    const keyArray: string[] = Object.keys(dataSerialized);
    // keyArray.splice(indexToRemove, numberToRemove);

    for (var i = 0; i < formNames.length; i++) {

      let indexOfKeyWeWant = keyArray[i];

      if (dataSerialized[indexOfKeyWeWant]?.includes("data:image")) {
        let blobbedImage: Blob = dataURItoBlob(
          dataSerialized[indexOfKeyWeWant]
        );

        if (formNames[i] === "document" || formNames[i] === "nid")
          formData.append(formNames[i], blobbedImage);
      } else
        formData.append(formNames[i], dataSerialized[indexOfKeyWeWant]);
    }

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token} `);

    var requestOptions: RequestInit = {
      method: "POST",
      body: formData,
      headers: myHeaders,
      redirect: "follow",
    };

    setDisabled(true);
    try {
      fetch(
        "https://api-online-subscription.connect.ly/api/online-subscription/register",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log('result false', result)
          if (result.message) {
            setMessage(result.message);
            localStorage.setItem("formId", JSON.stringify(result.id));
            stateObject.set(result.id)
            stateObject.attach(Persistence('formId'));

            Toastify({
              text: result.message,
              duration: 3000,
              backgroundColor: '#32a852',
            }).showToast();

            history.push("/checkout")
         
          }

          else {
            Toastify({
              text: "الرجاء التحقق من تعبئة جميع البيانات",
              duration: 3000,
              backgroundColor: '#e01c0a',
            }).showToast();
            $('#exampleModal').modal('show');
            
       
          }

        })
      setDisabled(false);
    }
    catch (e) {
      console.error(e);
    }
    setDisabled(false);
  };


  return (
    <>
    
        <>
          <h3 className="mt-4">البيانات الشخصية</h3>
          <FormStatus form={form} />
          <form ref={formRef} className="row">
            {form?.entries?.map((entry, index) => {
              return (
                <React.Fragment key={index}>
                  <div className={sizes[entry.size]} key={entry.id}>
                    <div className="form-group my-1">
                      <label htmlFor="{{$entry.id}}" id={entry.id.toString()}>
                        {entry.id === 2 || entry.id === 4 || entry.id === 5 ? "" : entry.question[context.language]}
                        <span className={`text-connect ${!entry.is_required ? "d-none" : ""}`} >*</span>
                      </label>
                      &nbsp;
                      <FormEntry
                        validationMessage={validationMessage}
                        disabled={disabled}
                        entry={entry}
                        index={index}
                        valueChange={inputChange}
                      />
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
            <div className="col-12 col-lg-2">
              <button disabled={disabled} className="btn btn-lg btn-connect  w-100">
                ارسل
              </button>
            </div>
          </form>
        </>
      
    </>
  );
};
